<template>
  <div class="search-result-item row f-radius b-white cursor-pointer d-flex align-items-center" @click="detailCustomer()">
    <div class="thumbnail">
      <div class="img"
           v-bind:style="imgStyle(img)"
           v-lazy:background-image="img"
      ></div>
    </div>
    <div class="w-40 pl-2">
      <div class="first">
        <p class="fs-12 mb-0 line-height-24">{{ reserved_at }}</p>
        <p class="fs-14 mb-0 line-height-24">No.{{ number }} {{ name }}</p>
      </div>
      <div class="info d-flex">
        <div class="">
          <div class="d-inline-flex">
            <b-badge class="fs-12" :style="{ 'background-color': getStatusColor(this.status) }">{{ status }}</b-badge>
            <b-badge class="fs-12 operate-staff-name m__left--5">{{ operate_staff_name }}</b-badge>
          </div>
          <div class="insight mb-1 d-flex">
            <div v-if="has_illust" class="mr-1 d-flex align-items-center">
              <img src="@/assets/images/icons/ic_image2.svg" alt="" width="30">
            </div>
            <div v-if="has_photo" class="mr-1 d-flex align-items-center">
              <img src="@/assets/images/icons/ic_camera2.svg" alt="" width="30">
            </div>
            <div v-if="has_concent" class="mr-1 d-flex align-items-center">
              <img src="@/assets/images/icons/ic_portrait2.svg" alt="" width="30">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="next d-flex align-items-center">
      <img src="@/assets/images/icons/ic_next2.svg" alt="" height="20">
    </div>
  </div>
</template>

<script>
export default {
  name: "ResultItem",
  props: {
    has_concent: {
      type: Boolean,
      default: false,
    },
    has_photo: {
      type: Boolean,
      default: false,
    },
    has_illust: {
      type: Boolean,
      default: false,
    },
    img: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    reserved_at: {
      type: String,
      default: '',
    },
    hour: {
      type: String,
      default: '',
    },
    number: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    operate_staff_name: {
      type: String,
      default: '',
    },
  },
  methods: {
    detailCustomer() {
      this.$emit('detail')
    }
  }
}
</script>

<style lang="scss" scoped>
.search-result-item {
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  max-height: none !important;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .thumbnail {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 20px;
    overflow: hidden;
    background-color: #E6E6E6;

    .img {
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .next {
    max-width: 10px;
  }

  .flex-auto {
    flex: auto;
  }
}
.w-40 {
  width: calc(100% - 106px);
}
.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
@media (min-width: 768px) {
  .w-40 {
    display: flex;
    .first {
      width: 70%;
    }
    .info {
      width: 30%;
      display: initial !important;
      span {
        display: inline-block;
        margin-right: 5px;
      }
    }
    .badge {
      height: calc(1rem + 3px);
      padding-top: 3px;
      &.operate-staff-name {
        margin-left: 0 !important;
        margin-top: 0 !important;
      }
    }
  }
}
@media (max-width: 300px) {
  .thumbnail {
    width: 60px !important;
    height: 60px !important;
  }
  .w-40 {
    width: calc(100% - 70px);
    p {
      line-height: 15px;
    }
  }
  .fs-12, .fs-14 {
    font-size: 10px;
  }
}
::v-deep .badge.operate-staff-name {
  max-width: 100%;
  overflow: hidden;
  color: $dark;
  background-color: $light-grey;
  font-weight: normal;
}
.line-height-24 {
  line-height: 24px !important;
}
</style>
