<template>
  <b-container>
    <div class="f-radius b-white row pt-4 pb-0 mb-4" :class="{ 'max-height-245px': isSearchDetail }">
      <b-container class="px-0">
        <b-row>
          <b-col cols="2" class="px-0">
            <p class="fs-14 mb-1">{{ $t('search.date') }}</p>
          </b-col>
          <b-col cols="4" class="px-0">
            <datepicker input-class="form-control bg-white text-center" v-model="chooseFrom"
                        placeholder="YYYY/MM/DD" format="yyyy/MM/dd" :language="languages.ja"></datepicker>
          </b-col>
          <b-col class="px-0">
            <div class="text-center">
              <img src="@/assets/images/icons/ic_tilde.svg" alt="" width="20">
            </div>
          </b-col>
          <b-col cols="4" class="px-0">
            <datepicker input-class="form-control bg-white text-center" wrapper-class="r-0" v-model="chooseTo"
                        placeholder="YYYY/MM/DD" format="yyyy/MM/dd" :language="languages.ja"></datepicker>
          </b-col>
        </b-row>

        <b-row class="mx-0">
          <b-col cols="2" class="px-0">
            <p class="fs-14 mb-1">{{ $t('search.range') }}</p>
          </b-col>
          <b-col cols="4" class="px-0">
            <b-dropdown :text="getTextRange" block>
              <b-dropdown-item v-for="(r, i) in ranges"
                               :key="i"
                               :active="r.value == range"
                               @click="chooseRange(r.value)"
              >
                {{ r.text }}
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>

        <b-row class="mx-0">
          <b-col cols="2" class="px-0">
            <p class="fs-14 mb-1">{{ $t('search.status') }}</p>
          </b-col>
          <b-col cols="4" class="px-0">
            <b-dropdown :text="getTextStatus" block>
              <b-dropdown-item v-for="(s, i) in statuses"
                               :key="i"
                               :active="s.value == status"
                               @click="chooseStatus(s.value)"
              >
                {{ s.text }}
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>

        <b-row class="mx-0" v-if="isSearchDetail">
          <b-col cols="2" class="px-0">
            <p class="fs-14 mb-1">{{ $t('search.customer_name') }}</p>
          </b-col>
          <b-col cols="4" class="px-0">
            <b-form-input v-model="customerName" type="text" debounce="500"></b-form-input>
          </b-col>
        </b-row>

        <b-row class="mx-0" v-if="isSearchDetail">
          <b-col cols="2" class="px-0">
            <p class="fs-14 mb-1">{{ $t('search.customer_number') }}</p>
          </b-col>
          <b-col cols="4" class="px-0">
            <b-form-input v-model="customerNo" type="number" debounce="500"></b-form-input>
          </b-col>
        </b-row>

        <b-row v-if="!isSearchDetail">
          <b-col cols="2" class="px-0"></b-col>
          <b-col cols="4" class="px-0">
            <p class="fs-12 mb-0 cursor-pointer d-inline-block text-decoration" @click="searchDetail">{{ $t('search.search_detail') }}</p>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div class="pb-5">
      <result-item v-for="(item, key) in searchResult"
                   :key="key"
                   :has_concent="item.customer_history_concent_array.length > 0"
                   :has_photo="item.customer_history_photo_array.length > 0"
                   :has_illust="item.customer_history_illust_array.length > 0"
                   @detail="detailCustomer(item.customer_no, item.customer_id, item.customer_history_id)"
                   :img="getImage(item.coalesce)"
                   :reserved_at="reservedFormat(item.reserved_at)"
                   :number="item.customer_no"
                   :name="item.customer_name"
                   :status="item.status"
                   :operate_staff_name="item.operate_staff_name"
      ></result-item>
    </div>
  </b-container>
</template>

<script>
import ResultItem from "./components/ResultItem";
import Datepicker from "vuejs-datepicker/dist/vuejs-datepicker.esm.js";
import * as lang from "vuejs-datepicker/src/locale";
import karuteService from "@/services/karute-api";
import {ACTION_BACK_PAGE} from "@/store/auth/actions";
import {ACTION_SET_SEARCH_INFO} from "@/store/search/actions";

export default {
  name: "Search",
  components: {
    ResultItem,
    Datepicker
  },
  data() {
    return {
      chooseFrom: '',
      chooseTo: '',
      range: null,
      status: null,
      customerName: '',
      customerNo: null,
      ranges: [
        {
          value: null,
          text: "選択する"
        },
        {
          value: 0,
          text: "個人"
        },
        {
          value: 1,
          text: "店舗"
        }
      ],
      statuses: [
        {
          value: null,
          text: "選択する"
        },
        {
          value: 0,
          text: "来店前"
        },
        {
          value: 1,
          text: "来店中"
        },
        {
          value: 2,
          text: "精算済"
        },
      ],
      searchResult: [],
      languages: lang,
      isSearchDetail: false,
      dataLoaded: false
    }
  },
  computed: {
    from() {
      if (this.chooseFrom) {
        return this.dateFormat(this.chooseFrom)
      }
      return ''
    },
    to() {
      if (this.chooseTo) {
        return this.dateFormat(this.chooseTo)
      }
      return ''
    },
    getTextRange() {
      return this.include(this.ranges, this.range)
    },
    getTextStatus() {
      return this.include(this.statuses, this.status)
    }
  },
  watch: {
    locationSelected() {
      this.getReserveList()
    },
    from() {
      if (this.dataLoaded) {
        this.getReserveList()
      }
    },
    to() {
      if (this.dataLoaded) {
        this.getReserveList()
      }
    },
    range() {
      if (this.dataLoaded) {
        this.getReserveList()
      }
    },
    status() {
      if (this.dataLoaded) {
        this.getReserveList()
      }
    },
    customerName() {
      if (this.dataLoaded) {
        this.getReserveList()
      }
    },
    customerNo() {
      if (this.dataLoaded) {
        this.getReserveList()
      }
    },
  },
  methods: {
    getReserveList() {
      if (!!this.from && !!this.to && this.from <= this.to) {
        const data = {
          target_date_from: this.from,
          target_date_to: this.to,
          range: this.range,
          status: this.status,
          customer_name: this.customerName,
          customer_no: this.customerNo,
        }
        karuteService.getReserveList(data).then((res) => {
          this.searchResult = res.data.result
        })
      }
    },
    searchDetail() {
      this.isSearchDetail = true
    },
    detailCustomer(customer_no, customer_id, customer_history_id) {
      this.$store.dispatch(ACTION_SET_SEARCH_INFO, {
        from: this.from,
        to: this.to,
        range: this.range,
        status: this.status,
      }).then(() => {
        this.$router.push({
          name: 'customer-detail',
          params: {
            customer_no: customer_no,
            customer_id: customer_id,
            customer_history_id: customer_history_id,
            target_date_from: this.from,
            target_date_to: this.to
          }
        }, () => {})
      })
    },
    chooseRange(val) {
      this.range = val
    },
    chooseStatus(val) {
      this.status = val
    },
    include(arr, value) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].value == value) {
          return arr[i].text
        }
      }
      return null
    }
  },
  created() {
    this.$store.dispatch(ACTION_BACK_PAGE, 'home')
  },
  mounted() {
    const searchInfo = this.$store.getters.searchInfo
    if (searchInfo) {
      this.chooseFrom = searchInfo.from
      this.chooseTo = searchInfo.to
      this.range = searchInfo.range
      this.status = searchInfo.status
    }
    this.$nextTick(() => {
      // Code that will run only after the entire view has been rendered

      this.getReserveList()
      this.dataLoaded = true
    })
  },
}
</script>

<style lang="scss" scoped>
.row {
  margin-right: auto;
  margin-left: auto;
}

.f-radius {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 20px;
  font-size: 1.1rem;
  line-height: 30px;
  max-height: 160px;

  &.b-white {
    background: #ffffff;
  }

  .space-between {
    justify-content: space-between;
  }

  &.max-height-245px {
    max-height: 245px !important;
  }
}

.text-decoration {
  text-decoration: underline;
}

.fs-14 {
  font-size: 14px;
}

.fs-12 {
  font-size: 12px;
}
</style>
<style scoped>
>>> .form-control, >>> .custom-select, >>> .dropdown .btn-secondary {
  height: calc(1.5em + 0.75rem - 1px);
  padding: 0.35rem 0.35rem;
  font-size: 12px;
  line-height: 16px;
  color: #000;
  background-color: #fff;
  border-color: #ced4da;
}
</style>
